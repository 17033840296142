<template>
  <div>
    <ed-periodico
      :key="$root.$session.versao"
    />
  </div>
</template>

<script>
import EdPeriodico from "@/components/periodico/editar";


export default {
  name: "pageCadastroEmpresa",
  props: {},
  components: {
    EdPeriodico
  },
  mounted() {

  },
  beforeDestroy() {
  },
  provide() {
    return {
    };
  },
  created() {},
  data() {
    return {
    };
  },
  methods: {
  },
};
</script>
