<template>
  <div>
    <ed-modal v-model="bind.boolExibirExames" width="100%">
      <div slot="title">
        Convocação de Exames
        {{
          formData && formData.funcionario
            ? " | " + formData.funcionario.strNome
            : ""
        }}
        | PERIÓDIO DE 
        {{
          formFiltros.arrayDataConvocacao[0]
        }} A 
        {{formFiltros.arrayDataConvocacao[1]}}
      </div>

      <div class="" slot="content">
        <ed-periodico-exame
          v-if="bind.boolExibirExames"
          :key="'editar-' + $root.$session.versao"
          :formData="formData"
        />
      </div>
    </ed-modal>

    <ed-table
    :key="$root.$session.versao"
      strModel="Consulta/Consulta"
      :headers="arrayHeaders"
      :rows="arrayRows"
      :pagination="objPagination"
      :filter="formFiltros"
      :loading="loading"
      :disabledFilters="false"
      disabledRegisterRows
      disabledEditRows
      disabledDeleteRows
      @search="getPeriodico"
      @filter="getPeriodico(true)"
      boolFiltroAberto
      :config="{
        registerName: 'Inserir nova consulta',
        filterName: 'Gerar Lista de Convocação',
      }"
    >
      <template slot="table-filter">
        <ed-input-date
          class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4"
          label="Data da Convocação"
          name="arrayData"
          v-model="formFiltros.arrayDataConvocacao"
          info="Data limite para realização do exame"
          rules="required"
          multiple
        />

        <ed-input-auto-complete
          class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4"
          v-model="formFiltros.intCadastroGeralEmpresaId"
          label="Empresa"
          name="intCadastroGeralEmpresaId"
          item-text="strNome"
          route="Cadastro/CadastroGeral"
          rules="required"
          :filters="{ intTipoCadastroGeralId: 58 }"
          clearable
        />

        <ed-input-auto-complete
          class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4"
          v-model="formFiltros.intFuncionarioId"
          label="Funcionário"
          name="intFuncionarioId"
          item-text="strNome"
          route="Cadastro/Funcionario"
          :filters="{
            intCadastroGeralEmpresaId: formFiltros.intCadastroGeralEmpresaId,
          }"
          clearable
        />

        <div class="col-12 text-left">
          <ed-alert type="warning">
            <b>Regras para Lista de Convocação</b>
            <br></br>
            <p>
              <span class="ed-full-width">1. Empresa com PCMSO</span>
              <span class="ed-full-width"
                >2. Funcionário vinculado na empresa</span
              >
              <span class="ed-full-width"
                >3. Funcionário sem data de demissão no cadastro da empresa
                vinculada</span
              >
              <span class="ed-full-width"
                >4. A data sugerida para o exame deve estar dentro da Data da
                Convocação desse relatório</span
              >
            </p>
          </ed-alert>
        </div>
      </template>
    </ed-table>
  </div>
</template>

<script>
import {
  EdTable,
  EdModal,
  EdAlert,
  EdInputAutoComplete,
  EdInputDate,
} from "@/components/common/form";
import EdPeriodicoExame from "./partials/exame.vue";

export default {
  name: "pageCadastroEmpresa",
  props: {
    intFuncionarioId: {
      type: [Number, String],
      required: false,
      default: null,
    },
    intCadastroGeralEmpresaId: {
      type: [Number, String],
      required: false,
      default: null,
    },
    arrayDataConvocacao: {
      type: [Array],
      required: false,
      default: function () {
        return [];
      },
    },
  },
  components: {
    EdTable,
    EdModal,
    EdAlert,
    EdInputAutoComplete,
    EdInputDate,
    EdPeriodicoExame,
  },
  mounted() {},
  beforeDestroy() {
    this.arrayHeaders = [];
    this.arrayRows = [];
    this.objPagination = null;
  },
  provide() {
    return {};
  },
  created() {},
  data() {
    return {
      loading: false,
      bind: {
        boolExibirExames: false,
        intTotalFiltrosApliados: 1,
      },

      formData: {},
      formFiltros: {
        intFuncionarioId: null,
        intCadastroGeralEmpresaId: null,
        boolListaConvocacao: false,
        arrayDataConvocacao: [],
      },
      arrayHeaders: [
        {
          text: "Empresa",
          sortable: true,
          showUnidade: true,
          value: "strNomeEmpresa",
        },
        {
          text: "Funcionário",
          sortable: true,
          showFuncao: true,
          showSetor: true,
          value: "strNomeFuncionario",
        },
        {
          text: "Data Sugerida",
          sortable: true,
          value: "strDataSugerida",
        },
        {
          text: "Observações",
          value: "strObservacao",
        },
      ],
      arrayRows: [],
      objPagination: null,
    };
  },
  methods: {
    abrirExames(data) {
      this.bind.boolExibirExames = true;
      this.formData = Object.assign(data, {});
    },

    getPeriodico(boolListaConvocacao = false) {

      this.loading = true;

      let formFiltros = _.cloneDeep(this.formFiltros)
      formFiltros.boolListaConvocacao = boolListaConvocacao;
      
      this.$root.$request
        .get("Consulta/Periodico", formFiltros)
        .then((objResult) => {
          if (objResult.status == 200) {

            this.loading = false;
            let arrayRows = [];
            this.objPagination = objResult.result

            objResult.result.data.forEach((objModel) => {
              let item = {
                intId: objModel.intId,
                strNomeEmpresa: objModel.empresa.strNome,
                strNomeFuncionario: objModel.funcionario.strNome,
                strNomeUnidade: objModel.unidade.strNome,
                strObservacao:
                  objModel.strObservacao && objModel.strObservacao.length
                    ? objModel.strObservacao.join("<br>")
                    : "---",
                strDataSugerida: objModel.strDataSugerida
                  ? this.$utilidade.toDate(objModel.strDataSugerida, true)
                  : "---",
                buttons: [],
                _item: objModel,
              };


              if (objModel.strExame.length) {
                item.buttons.push({
                  icone: this.$utilidade.getIcone("exame"),
                  color: "warning",
                  title: "Visualizar informações dos exames",
                  click: this.abrirExames,
                });
              }

              arrayRows.push(item);
            });

            this.arrayRows = arrayRows;
          }
        });
    },
  },
};
</script>
