<template>
  <div>
    <ed-simple-table v-if="formData" disabledSearchRows disabledRegisterRows>
      <thead v-if="formData.strExame.length">
        <tr>
          <th>Exame</th>
          <th>Última Consulta</th>
          <th>Data Sugerida para Refazer</th>
        </tr>
      </thead>
      <tbody>
        <template v-if="getExames(true).length">
          <tr>
            <td
              colspan="3"
              align="center"
              class="text-left"
              style="background: #74cc97; font-weight: bold"
            >
              EXAMES APTO PARA SER REALIZADO
            </td>
          </tr>

          <tr :key="index + '-apto-lista'" v-for="(objModel, index) in getExames(true)">
            <td align="left">
              {{ objModel.strNomeExame }}

              <template v-if="objModel.strLog && objModel.strLog.length">
                <span
                  class="ed-full-width ed-table-info"
                  :style="
                    'color: ' +
                    (objError.strCor ? objError.strCor : '') +
                    '; font-weight: bold'
                  "
                  v-for="(objError,j) in objModel.strLog"
                  :key="j"
                >
                  {{ objError.strMsg }}
                </span>
              </template>
            </td>

            <td align="left">
              <template v-if="objModel.intConsultaId">
                <b>Consulta Nº {{ objModel.intConsultaId }}</b> em
                {{ $utilidade.toDate(objModel.strDataExame, true) }}
              </template>
              <template v-if="!objModel.intConsultaId">---</template>
            </td>

            <td align="left">
              {{
                objModel.strDataSugerida
                  ? $utilidade.toDate(objModel.strDataSugerida, true)
                  : "---"
              }}
            </td>
          </tr>
        </template>

        <template v-if="getExames(false).length">
          <tr>
            <td
              colspan="3"
              align="center"
              class="text-left mt-2"
              style="background: #db7878; font-weight: bold"
            >
              EXAMES INAPTO PARA SER REALIZADO
            </td>
          </tr>

          <tr :key="index + '-inaapto-lista'" v-for="(objModel, index) in getExames(false)">
            <td align="left">
              {{ objModel.strNomeExame }}

              <template v-if="objModel.strLog && objModel.strLog.length">
                <span
                  class="ed-full-width ed-table-info"
                  :style="
                    'color: ' +
                    (objError.strCor ? objError.strCor : '') +
                    '; font-weight: bold'
                  "
                  v-for="(objError,j) in objModel.strLog"
                  :key="j"
                >
                  {{ objError.strMsg }}
                </span>
              </template>
            </td>

            <td align="left">
              <template v-if="objModel.intConsultaId">
                <b>Consulta Nº {{ objModel.intConsultaId }}</b> em
                {{ $utilidade.toDate(objModel.strDataExame, true) }}
              </template>
              <template v-if="!objModel.intConsultaId">---</template>
            </td>

            <td align="left">
              {{
                objModel.strDataSugerida
                  ? $utilidade.toDate(objModel.strDataSugerida, true)
                  : "---"
              }}
            </td>
          </tr>
        </template>
      </tbody>
    </ed-simple-table>
  </div>
</template>

<script>
import { EdSimpleTable } from "@/components/common/form";

export default {
  name: "pageCadastroEmpresa",
  props: {
    formData: {
      type: [Object],
      required: true,
      default: function () {
        return null;
      },
    },
  },
  components: {
    EdSimpleTable,
  },
  inject: [],
  mounted() {
    this.initialize();
  },
  beforeDestroy() {},
  created() {},
  data() {
    return {};
  },
  watch: {},
  computed: {},
  methods: {
    initialize() {},
    getExames(boolRealizarExame) {
      let arrayExames = [];

      this.formData.strExame.forEach((element) => {
        if (element.boolRealizarExame == boolRealizarExame) {
          arrayExames.push(element);
        }
      });

      return arrayExames;
    },
  },
};
</script>
